<template>
  <div class="insurance--wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      中标公告
    </div>
    <div class="content">
      <search-form ref="searchForm" @refresh="$refs.table.refresh()"></search-form>
      <p-table
        ref="table"
        row-key="orderNo"
        :scroll="{ y: 0 }"
        extraHeight="380"
        :sourceData="getDataApi"
        :columns="columns"
      >
        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="$refs.detailsModal.show(record, 2)" v-perms="'details'">查看</a-button>
        </template>
      </p-table>
    </div>
    <detailsModal ref="detailsModal"/>
  </div>
</template>

<script>
import searchForm from './search-form.vue'
import { queryPageByCondition } from '@/api/TenderingBidding'
import { zbPubStatus } from '@/views/TenderingBidding/dict'
import detailsModal from '../TenderingBidding/details-modal.vue'

export default {
  name: 'Insurance',
  components: {
    searchForm,
    detailsModal
  },
  data () {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        {
          title: '中标标题',
          dataIndex: 'title',
          key: 'title'
        },
        {
          title: '项目类型',
          dataIndex: ['announcement', 'type'],
          key: 'type'
        },
        {
          title: '合作企业名称',
          dataIndex: ['enterpriseInfo', 'enterpriseName'],
          key: 'enterpriseName'
        },
        {
          title: '统一社会信用代码 ',
          dataIndex: ['enterpriseInfo', 'creditCode'],
          key: 'creditCode'
        },
        {
          title: '企业类型',
          dataIndex: ['enterpriseInfo', 'enterpriseType'],
          key: 'enterpriseType'
        },
        {
          title: '企业规模',
          dataIndex: ['enterpriseInfo', 'enterpriseScale'],
          key: 'enterpriseScale'
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          customRender: text => {
            const data = zbPubStatus.find(item => item.value == text)
            return data?.label
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  computed: {
    enterpriseInfo () {
      return this.$store.getters.userInfo?.enterpriseInfoDto
    }
  },
  created () {},
  methods: {
    getDataApi (params) {
      const form = this.$refs.searchForm.getQuery()
      return queryPageByCondition({
        ...params,
        ...form,
        enterpriseName: this.enterpriseInfo.enterpriseName,
        status: '1,2,3,5'
      })
    }
  }
}
</script>

<style lang="less">
@import url('../../common/style');
.insurance--wrapper {
  .table-btn {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
